import Debug from 'debug';

import { resolveItemApi } from '~/app/lib/songwhipApi/resolve';
import dispatchFetchItemSuccess from '../lib/dispatchFetchItemSuccess';
import { Dispatch, SelectedItem, State } from '../types';

import { selectItemByPathGlobal, selectPathError } from './selectors';

import {
  FETCH_PATH_START,
  FETCH_PATH_SUCCESS,
  FETCH_PATH_ERROR,
} from './types';

const debug = Debug('songwhip/store/paths/actions');

export const fetchItemByPathAction =
  ({
    path,
    silent,
    shouldRefetch,
  }: {
    path: string;
    silent?: boolean;

    shouldRefetch?: (params: {
      state: State;
      existingItem: SelectedItem;
    }) => boolean;
  }) =>
  async (dispatch: Dispatch, getState: () => State) => {
    try {
      if (!path) return;

      debug('fetch item by path: %s', path);

      const state = getState();
      const existingItem = selectItemByPathGlobal(state, path);
      const itemError = selectPathError(state, path);

      const canUseExistingItem =
        existingItem &&
        !itemError &&
        !(shouldRefetch && shouldRefetch({ state, existingItem }));

      // item already in store and not in an error state: no action required
      // if item has error then we refetch it as this may just be network issue
      if (canUseExistingItem) {
        return { item: existingItem };
      }

      dispatch({
        type: FETCH_PATH_START,
        path,
      });

      const { data: payload, fromApiCache } = await resolveItemApi({
        path,
        showToastOnError: !silent,
      });

      dispatchFetchItemSuccess(dispatch, payload);

      dispatch({
        type: FETCH_PATH_SUCCESS,
        path,
        payload,
      });

      return { item: selectItemByPathGlobal(getState(), path), fromApiCache };
    } catch (error) {
      dispatch({
        type: FETCH_PATH_ERROR,
        path,
        error,
      });

      // We re-throw here so that upstream try-catch blocks
      // can respond to errors inline, instead of using the store.
      // If the dispatch isn't `await`ed then it shouldn't break flow.
      if (!silent) throw error;
    }
  };
