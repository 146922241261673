import { MappedPartialAlbum } from '~/app/lib/mapper';
import { PageSectionPreset } from '../../types';
import { PageSectionTypes } from '../types';
import { ReleasesSectionProps } from './types';

export const toReleasesSectionPreset = (props: {
  title: string;
  navTitle: string;
  albums: MappedPartialAlbum[] | undefined;
}): PageSectionPreset<ReleasesSectionProps> => {
  return {
    component: PageSectionTypes.RELEASES,

    props: {
      ...props,
      sectionId: 'releases',
    },
  };
};
