import { ItemTypes, Links } from '~/types';

import {
  ArtistPayload,
  AlbumPayload,
  OwnedByAccount,
  TrackPayload,
} from '../songwhipApi/types';

export enum OwnerPermissions {
  READ = 'read',
  WRITE = 'write',
}

export interface CustomBrand {
  urlPattern: string;
  name: string;
  icon: string;
  dynamicColoring?: boolean;
}

export type OwnedByAccountMapped = Omit<
  OwnedByAccount,
  'subscriptionEndsAt'
> & {
  subscriptionEndsAtTimestamp: number | undefined;
};

export type OwnedByAccountsMapped = OwnedByAccountMapped[] | undefined;

export type ArtistPayloadNested = Omit<
  ArtistPayload,
  'links' | 'linksCountries' | 'description'
>;

export type MappedArtist = {
  type: ItemTypes.ARTIST;
  id: number;
  path: string;
  pagePath: string;
  name: string;
  description?: string;
  image?: string;
  links?: Links;
  linksCountries?: string[];
  sourceCountry: string;
  createdAtTimestamp?: number;
  refreshedAtTimestamp?: number;
  deleted?: boolean;
  config?: ArtistPayload['config'];
  spotifyId: string | undefined;
  ownedByAccounts?: OwnedByAccountsMapped;
  customLinks?: ArtistPayload['customLinks'];
  albums?: MappedPartialAlbum[];
  videos?: ArtistPayload['videos'];
  shows?: ArtistPayload['shows'];
};

export type PartialAlbum = Pick<
  AlbumPayload,
  | 'id'
  | 'path'
  | 'name'
  | 'image'
  | 'releaseDate'
  | 'updatedAt'
  | 'createdAt'
  | 'upc'
  | 'isExplicit'
  | 'artists'
>;

export type MappedPartialAlbum = Omit<
  ArtistPayload['albums'][0],
  'releaseDate' | 'image'
> & {
  pagePath: string;
  releaseDate: number;
  image: string;
};

export interface MappedAlbum {
  type: ItemTypes.ALBUM;
  id: number;
  path: string;
  pagePath: string;
  name: string;
  image: string | undefined;
  links?: Links;
  linksCountries?: string[];
  sourceCountry: string;
  artists: MappedArtist[];
  createdAtTimestamp?: number;
  refreshedAtTimestamp?: number;
  isPrerelease: boolean | undefined;
  isDraft: boolean | undefined;
  releaseDate?: string;
  upc?: string;
  config?: AlbumPayload['config'];

  /**
   * A list of all Accounts that 'own' this Album.
   */
  ownedByAccounts?: OwnedByAccountsMapped;

  /**
   * Alternative custom page urls using CustomDomains.
   */
  customLinks?: AlbumPayload['customLinks'];

  /**
   * @deprecated use addons
   */
  facebookPixelId?: string;
}

export type MappedTrack = {
  type: ItemTypes.TRACK;
  id: number;
  path: string;
  pagePath: string;
  name: string;
  image?: string;
  links: Links;
  linksCountries?: string[];
  sourceCountry: string;
  createdAtTimestamp?: number;
  refreshedAtTimestamp?: number;
  artists: MappedArtist[];
  config?: TrackPayload['config'];

  /**
   * A list of all Accounts that 'own' this Album.
   */
  ownedByAccounts?: OwnedByAccountsMapped;

  /**
   * Alternative custom page urls using CustomDomains.
   */
  customLinks?: TrackPayload['customLinks'];

  /**
   * @deprecated
   */
  facebookPixelId?: string;
};

export type Item = MappedArtist | MappedAlbum | MappedTrack;
