import { OpenExternalServiceEvent } from '@theorchard/songwhip-events';
import { useMemo } from 'react';
import Debug from 'debug';

import { resolveServiceDataFromUrl } from '../../getServiceDisplayData';
import { useItemContext } from '~/app/components/ItemPage/ItemPageContext';
import { useTracker } from '~/app/lib/tracker/useTracker';
import { ItemTypes } from '../../songwhipApi/types';
import openWindow from '../../utils/openWindow';
import { useStore } from '../../store/redux';

import openAudiomack from './openAudiomack';
import openAppleMusic from './openAppleMusic';
import openSpotify from './openSpotify';
import openQobuz from './openQobuz';

const debug = Debug('songwhip/open-external-service');

export type OpenExternalServiceParams = {
  url: string;
  trackerParams: Omit<OpenExternalServiceEvent, 'type'>;
};

/**
 * Handles opening an external service url in the best
 * way possible and ensuring the event is tracked.
 */
const useOpenExternalService = () => {
  const { trackEvent } = useTracker();
  const { session } = useStore().getState();

  const {
    data: { item },
  } = useItemContext();

  return useMemo(
    () => (url: string) => {
      debug('open', url);

      const service = resolveServiceDataFromUrl(url);

      // the artistId/albumId/trackId is now part of `context`
      trackEvent({
        type: 'open-external-service',
        serviceKey: service.key,
        serviceName: service.name,
        url,
      });

      return (
        openAppleMusic(
          url,
          item.type === ItemTypes.ARTIST ? item.name : item.artistName
        ) ||
        openSpotify({
          url,
          utmSource: session.utmSource,
          utmMedium: session.utmMedium,
          pagePath: item.pagePath,

          // only forward utm params to spotify for orchard/sme/awal pages
          withUtmParams: item.isOrchardPage,
        }) ||
        openAudiomack(url) ||
        openQobuz(url) ||
        // disabled due to affiliate program pause
        // openTidal(params) ||
        openDefault(url)
      );
    },
    []
  );
};

/**
 * Opens a url via the services/open tracking proxy.
 */
const openDefault = (url: string) => {
  debug('open', url);
  openWindow(url);
  return true;
};

export default useOpenExternalService;
